.containerGrid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.menuItem {
  grid-column: 1;
  grid-row: 1 / span 5;
}

.headerItem {
  grid-column: 2;
  grid-row: 1;
  z-index: unset;
}

.titleItem {
  grid-column: 2;
  grid-row: 2;
  font-weight: bolder;
}

.dropdownTime {
  margin-left: 20px;
}

.legendItem {
  grid-column: 2;
  grid-row: 3;
  width: 100%;
}

.graphHeaderItem {
  grid-column: 2;
  grid-row: 4;
  font-weight: bolder;
}

.graphItem {
  grid-column: 2;
  grid-row: 5;
}

.containerFlex {
  display: flex;
  columns: auto;
  flex-wrap: wrap;
  word-wrap: break-word;
  gap: 10px;
}

.flexItem {
  border-width: 1px;
  border-style: groove;
  align-content: center;
  text-align: center;
  justify-content: space-between;
  width: 24%;
}

.itemBold {
  padding-top: 10px;
  font-weight: bold;
}

.itemBoldCenter {
  padding-top: 10px;
  font-size: x-large;
  font-weight: bold;
}

.itemRight {
  margin-right: 150px;
  float: right;
}

.padTop {
  padding-top: 10px;
}

.ratio {
  height: 15vh;
}

.largeRatio {
  height: 50vh;
  width: 82vw;
}